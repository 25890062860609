import {
  mdiViewDashboardOutline,
  mdiNotebookEditOutline,
  mdiBookOpenVariant,
  mdiPointOfSale,

  // mdiMagnifyPlusOutline,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboardOutline,
    to: { name: 'dashboard' },
    resource: 'Dashboard',
    module: 'Public',
    action: 'read',
  },
  {
    title: 'Billing',
    icon: mdiNotebookEditOutline,
    resource: 'Clerical',
    module: 'Billing Management',
    action: 'read',
    children: [
      {
        title: 'Quotations',
        to: { name: 'quotation' },
        resource: 'Clerical',
        module: 'Billing Management',
        action: 'read',
      },
      {
        title: 'Invoices',
        to: { name: 'invoice' },
        resource: 'Clerical',
        module: 'Billing Management',
        action: 'read',
      },
      {
        title: 'Receipts',
        to: { name: 'receipt' },
        resource: 'Clerical',
        module: 'Billing Management',
        action: 'read',
      },
      {
        title: 'Products & Services',
        to: { name: 'item' },
        resource: 'Clerical',
        module: 'Billing Management',
        action: 'read',
      },
      {
        title: 'Customers',
        to: { name: 'customer' },
        resource: 'Clerical',
        module: 'Billing Management',
        action: 'read',
      },
    ],
  },
  {
    title: 'Expenses',
    icon: mdiPointOfSale,
    resource: 'Expenses',
    module: 'Expenses',
    action: 'read',
    children: [
      {
        title: 'Bills',
        to: { name: 'bill' },
        resource: 'Expenses',
        module: 'Expenses',
        action: 'read',
      },
      {
        title: 'Products & Services',
        to: { name: 'expenses-item' },
        resource: 'Expenses',
        module: 'Expenses',
        action: 'read',
      },
      {
        title: 'Vendors',
        to: { name: 'vendor' },
        resource: 'Expenses',
        module: 'Expenses',
        action: 'read',
      },
    ],
  },
  {
    title: 'Knowledge Base',
    icon: mdiBookOpenVariant,
    resource: 'KnowledgeBase',
    action: 'read',
    module: 'Public',
    children: [
      {
        title: 'Payment Methods',
        to: { name: 'payment_method' },
        resource: 'KnowledgeBase',
        module: 'Public',
        action: 'read',
      },
      {
        title: 'Chart of Accounts',
        to: { name: 'chart_of_accounts' },
        resource: 'KnowledgeBase',
        module: 'Public',
        action: 'read',
      },
    ],
  },

  /*
  {
    title: 'Tutorials',
    icon: mdiMagnifyPlusOutline,
    to: { name: 'tutorial-group' },
    resource: 'Public',
    module: 'Public',
    action: 'read',
  },
  */
]
