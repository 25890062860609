const knowledgeBase = [
  {
    path: '/knowledge-base/payment-methods',
    name: 'payment_method',
    component: () => import('@/views/knowledge-base/PaymentMethod.vue'),
    meta: {
      layout: 'content',
      resource: 'KnowledgeBase',
      module: 'Public',
    },
  },
  {
    path: '/knowledge-base/chart-of-accounts',
    name: 'chart_of_accounts',
    component: () => import('@/views/knowledge-base/chart-of-accounts/ChartOfAccounts.vue'),
    meta: {
      layout: 'content',
      resource: 'KnowledgeBase',
      module: 'Public',
    },
  },
]

export default knowledgeBase
