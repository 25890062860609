export default {
  truncate(payload, length) {
    const string = `${payload.slice(0, length)}...`

    return payload.length > length ? string : payload
  },
  formatDate(date) {
    if (!date) return null
    const [year, month, day] = date.split('-')

    return `${day}/${month}/${year}`
  },
  dateRangeText(dateRange) {
    if (Date.parse(dateRange[0]) > Date.parse(dateRange[1])) {
      const [start, end] = dateRange
      dateRange[0] = end
      dateRange[1] = start
    }

    const startDateText = this.formatDate(dateRange[0])
    const showEndDate = dateRange[0] !== dateRange[1] && this.formatDate(dateRange[1]) !== null
    const endDateText = showEndDate ? ` - ${this.formatDate(dateRange[1])}` : ''

    return `${startDateText}${endDateText}`
  },
  formatCurrency(currency) {
    if (!currency) return null

    return `$${(parseFloat(currency)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  },
}
