import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import { axios } from '@axios'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

import invoiceStoreModule from './clerical/invoice/invoiceStoreModule'
import quotationStoreModule from './clerical/quotation/quotationStoreModule'
import receiptStoreModule from './clerical/receipt/receiptStoreModule'
import clericalSettingsStoreModule from './clerical/clericalSettingsStoreModule'
import dashboardStoreModule from './dashboard/dashboardStoreModule'
import releaseNoteStoreModule from './dashboard/releaseNoteStoreModule'
import billStoreModule from './expenses/bill/billStoreModule'
import expensesSettingsStoreModule from './expenses/expensesSettingsStoreModule'
import knowledgeBaseStoreModule from './knowledge-base/knowledgeBaseStoreModule'
import settingsStoreModule from './pages/settings/settingsStoreModule'
import tutorialStoreModule from './tutorial/tutorialStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    organization: {},
    settings: {},
    snackbar: {
      content: '',
      status: '',
    },
  },
  getters: {
    isSnackbarVisible(state) {
      return state.snackbar.isSnackbarVisible
    },
  },
  mutations: {
    SHOW_SNACKBAR(state, value) {
      state.snackbar.content = value.content
      state.snackbar.status = value.status
    },
    SET_SETTINGS(state, value) {
      state.settings = value
    },
    SET_ORGANIZATION(state, value) {
      state.organization = value
    },
  },
  actions: {
    showSnackbar({ commit }, value) {
      commit('SHOW_SNACKBAR', value)
    },
    fetchSettings() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/settings')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSettings(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/settings', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/messages', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setSettings({ commit }, value) {
      commit('SET_SETTINGS', value)
    },
    fetchOrganization() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/organizations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrganization(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/v1/organizations', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setOrganization({ commit }, value) {
      commit('SET_ORGANIZATION', value)
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    invoiceStore: invoiceStoreModule,
    quotationStore: quotationStoreModule,
    receiptStore: receiptStoreModule,
    clericalSettingsStore: clericalSettingsStoreModule,
    billStore: billStoreModule,
    expensesSettingsStore: expensesSettingsStoreModule,
    knowledgeBaseStore: knowledgeBaseStoreModule,
    dashboardStore: dashboardStoreModule,
    releaseNoteStore: releaseNoteStoreModule,
    settingsStore: settingsStoreModule,
    tutorialStore: tutorialStoreModule,
  },
})
