const clerical = [
  {
    path: '/clerical/invoice',
    name: 'invoice',
    component: () => import('@/views/clerical/invoice/InvoiceList.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/invoice/form/:id?',
    name: 'invoice-form',
    component: () => import('@/views/clerical/invoice/InvoiceForm.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/invoice/:id',
    name: 'invoice-view',
    component: () => import('@/views/clerical/invoice/InvoiceView.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/receipt',
    name: 'receipt',
    component: () => import('@/views/clerical/receipt/ReceiptList.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/receipt/form/:id?',
    name: 'receipt-form',
    component: () => import('@/views/clerical/receipt/ReceiptForm.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/receipt/:id',
    name: 'receipt-view',
    component: () => import('@/views/clerical/receipt/ReceiptView.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/quotation',
    name: 'quotation',
    component: () => import('@/views/clerical/quotation/QuotationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/quotation/form/:id?',
    name: 'quotation-form',
    component: () => import('@/views/clerical/quotation/QuotationForm.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/quotation/:id',
    name: 'quotation-view',
    component: () => import('@/views/clerical/quotation/QuotationView.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/products-services',
    name: 'item',
    component: () => import('@/views/clerical/Item.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/customers',
    name: 'customer',
    component: () => import('@/views/clerical/customer/Customer.vue'),
    meta: {
      layout: 'content',
      resource: 'Clerical',
      module: 'Billing Management',
    },
  },
  {
    path: '/clerical/customers/statement/:id?',
    name: 'customer-statement',
    component: () => import('@/views/clerical/customer/CustomerStatement.vue'),
    meta: {
      layout: 'content',
      resource: 'KnowledgeBase',
      module: 'Public',
    },
  },
]

export default clerical
