const pages = [
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
    },
  },
  {
    path: '/pages/settings',
    name: 'page-settings',
    component: () => import('@/views/pages/settings/Settings.vue'),
    meta: {
      layout: 'content',
      resource: 'Profile',
      module: 'Public',
    },
  },
]

export default pages
