import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSubscriptions() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/subscriptions')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createSubscription(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/subscriptions', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSubscription(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/subscriptions/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMembers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/members')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentMethods() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/payment_methods')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPaymentMethod() {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/payment_methods')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentMethod(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/payment_methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detachPaymentMethod(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/payment_methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/payments', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadPayment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/payments/${id}.pdf`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    createInvitation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('auth/invitation', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resendInvitation(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/invitation/resend', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMember(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/members/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBilling(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/billings/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
