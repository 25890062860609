import { canNavigate, isSubscribed } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from './pages'
import knowledgeBase from './knowledge-base'
import clerical from './clerical'
import expenses from './expenses'

// import tutorial from './tutorial'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      const authHeaders = localStorage.getItem('authHeaders')
      const isLoggedIn = authHeaders && localStorage.getItem('userAbility')

      if (isLoggedIn) return { name: 'dashboard' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      module: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Dashboard',
      module: 'Public',
    },
  },
  ...pages,
  ...knowledgeBase,
  ...clerical,
  ...expenses,

  // ...tutorial,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const authHeaders = localStorage.getItem('authHeaders')
  const isLoggedIn = authHeaders && localStorage.getItem('userAbility')

  if (!canNavigate(to) || !isSubscribed(to)) {
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) next('/')

  return next()
})

export default router
