import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/invoices', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceMetas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/invoices/metas', { params: queryParams })
          .then(response => (resolve(response)))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceReceipts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/invoices/${params.id}/receipts`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/invoices/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/clerical/invoices', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/clerical/invoices/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/clerical/invoices/${id}/duplicate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/clerical/invoices/${id}.pdf`, {
          method: 'GET',
          headers: {
            ...JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
            'Content-Type': 'application/octet-stream',
          },
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)
        urlParams.append('status', params.status)

        fetch(`${baseURL}/api/v1/clerical/invoices.csv?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    remindInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/clerical/invoices/reminder', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/clerical/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivities(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/invoices/${params.id}/activity`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
