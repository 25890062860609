import encryptionService from '@/services/encryption.service'
import ability from './ability'

const encryptedModules = localStorage.getItem('organizationModules')
const modules = encryptedModules ? JSON.parse(encryptionService.decrypt(encryptedModules)) : null

export const isSubscribed = to => {
  if (modules) return to.matched.some(route => modules.includes(route.meta.module))

  return true
}
export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

export const _ = undefined
