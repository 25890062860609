const expenses = [
  {
    path: '/expenses/bill',
    name: 'bill',
    component: () => import('@/views/expenses/bill/BillList.vue'),
    meta: {
      layout: 'content',
      resource: 'Expenses',
      module: 'Expenses',
    },
  },
  {
    path: '/expenses/bill/form/:id?',
    name: 'bill-form',
    component: () => import('@/views/expenses/bill/BillForm.vue'),
    meta: {
      layout: 'content',
      resource: 'Expenses',
      module: 'Expenses',
    },
  },
  {
    path: '/expenses/products-services',
    name: 'expenses-item',
    component: () => import('@/views/expenses/Item.vue'),
    meta: {
      layout: 'content',
      resource: 'Expenses',
      module: 'Expenses',
    },
  },
  {
    path: '/expenses/vendors',
    name: 'vendor',
    component: () => import('@/views/expenses/Vendor.vue'),
    meta: {
      layout: 'content',
      resource: 'Expenses',
      module: 'Expenses',
    },
  },
]

export default expenses
