import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPaymentMethods(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/knowledge_base/payment_methods', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchIncomeAccounts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/chart_of_accounts/income')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchExpensesAccounts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/chart_of_accounts/expenses')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoryGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/category_groups/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoryGroups(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/v1/category_groups', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentMethod(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/knowledge_base/payment_methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPaymentMethod(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/knowledge_base/payment_methods', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentMethod(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/knowledge_base/payment_methods/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentMethod(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/knowledge_base/payment_methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
