export default {
  Dashboard: 'Papan pemuka',
  'Knowledge Base': 'Butiran Asas',
  'Products & Services': 'Produk & Perkhidmatan',
  'Payment Methods': 'Kaedah Pembayarar',
  'Chart of Accounts': 'Carta Account',
  Categories: 'Kategori',
  Customers: 'Pelanggan',
  Clerical: 'Perkeranian',
  Receipts: 'Resit',
  Invoices: 'Invois',
  Quotations: 'Sebutan Harga',
  Expenses: 'Perbelanjaan',
  Bills: 'Bil',
  Vendors: 'Pembekal',
  'Under Maintenance': 'Di bawah penyelenggaraan',
  Error: 'Kesilapan',
  'Coming Soon': 'Akan datang',
  'Not Authorized': 'Tidak dibenarkan',

  // Table
  Datetime: 'Tempoh Masa',
  Description: 'Penghuraian',
  Status: 'Status',
  'Insurance Policy No.': 'No. Polisi Insurans',
  Branch: 'Cawangan',
  Issuer: 'Kerani',
  'Car Registration No.': 'No. Pendaftaran Kereta',
  'Customer Name': 'Nama Pelanggan',
  'Company Name': 'Nama Syarikat',
  'Surplus Hibah': 'Lebihan Hibah',
  NCD: 'NCD',
  'Sum Insured': 'Jumlah Diinsuranskan',
  'Insurance Premium': 'Premium Insurans',
  'After Discount': 'Selepas Diskaun',
  'Net Payment': 'Pembayaran Bersih',
  'Road Tax': 'Cukai Jalan',
  'Additional Fees': 'Bayaran Tambahan',
  Total: 'Jumlah',
  Party: 'Pihak',
  'Payment Method': 'Cara Pembayaran',
  'Insurance Product': 'Produk Insurans',
  Insurance: 'Insurans',
  'Bluecard Finance': 'Kewangan Bluecard',
  Period: 'Tempoh',
  Profit: 'Keuntungan',
  'General Remarks': 'Kenyataan Umum',
  Actions: 'Tindakan',

  // Invoices
  'Create Invoice': 'Buat Invois',
  'Search Invoice': 'Cari Invois',
  '#Invoice No.': '#No. Invois',
  'Confirm Invoice': 'Simpan Invois',
  Outstanding: 'Belum Dijelaskan',
  Paid: 'Selesai',
  'This invoice has been voided.': 'Invois ini telah dibatalkan.',

  // Receipts
  'Create Receipt': 'Buat Resit',
  'Search Receipt': 'Cari Resit',
  '#Receipt No.': '#No. Resit',
  'Confirm Receipt': 'Simpan Resit',
  'This receipt has been voided.': 'Resit ini telah dibatalkan.',

  // Quotations
  'Create Quotation': 'Buat Sebutan Harga',
  'Search Quotation': 'Cari Sebutan Harga',
  '#Quotation No.': '#No. Sebutan Harga',
  'Confirm Quotation': 'Simpan Sebutan Harga',
  'This quotation has been voided.': 'Sebutan harga ini telah dibatalkan.',

  // Misc
  'Select Status': 'Pilih Status',
  'Select Branch': 'Pilih Cawangan',
  'Set Dates': 'Tetapkan Haribulan',
  'Select Product Types': 'Pilih Jenis Produk',
  'Select Accounts': 'Pilih Akaun',
  'Select View': 'Pilih Paparan',
  'Select Grouping': 'Pilih Pengelompokan',
  'Select Insurances': 'Pilih Insurans',
  Rows: 'Barisan',
  'Print Report': 'Cetak Laporan',
  Print: 'Cetak',
  'Print full report': 'Cetak laporan penuh',
  'Print runner report': 'Cetak laporan runner',
  'Summary for': 'Ringkasan untuk',
  'Total collection by cash': 'Jumlah kutipan melalui cash',
  'Total collection by bank': 'Jumlah kutipan melalui bank',
  'Total collection by cheque': 'Jumlah kutipan melalui cek',
  'Total overall collection': 'Jumlah kutipan kesuluruhan',
  'View Log': 'Lihat Catatan',
  Logout: 'Log Keluar',
  Today: 'Hari ini',
  'Last 7 days': '7 hari kebelakangan',
  'Last 1 year': '1 tahun kebelakangan',
  'Profit Breakdown': 'Pecahan Keuntungan',
  Day: 'Hari',
  Week: 'Minggu',
  Month: 'Bulan',
  Branches: 'Cawangan',
  Accounts: 'Akaun',
  Principles: 'Insurans',
  'Total Customer Payment': 'Jumlah Pembayaran Pelanggan',
  'Total Net Payment': 'Jmlah Pembayaran Bersih',
  'Total Profit': 'Jumlah Keuntungan',
  Settings: 'Tetapan',
  Account: 'Akaun',
  Nickname: 'Nama Panggilan',
  Name: 'Nama',
  'E-mail': 'E-mel',
  'Enter New Password': 'Masukkan Kata Laluan Baru',
  'Only change your password when necessary': 'Tukar kata laluan anda apabila perlu sahaja',
  Save: 'Simpan',
  'Please ensure that all fields are filled correctly as this record will not be editable after confirming.': 'Sila pastikan semua ruangan diisi dengan betul kerana rekod ini tidak akan dapat diedit setelah disimpan.',
  'How to use the search bar': 'Cara menggunakan bar carian',
  'You can search using': 'Anda boleh mencari menggunakan',
  'How to use Autofill': 'Cara menggunakan Autofill',
  'Press the autofill button and wait for the form to be filled automatically!': 'Tekan butang autofill dan tunggu sehingga borang diisi secara automatik!',

  // Roles
  Clerk: 'Kerani',
  Auditor: 'Juruaudit',
  Admin: 'Pentadbir',
}
