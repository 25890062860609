import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReceipts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/receipts', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/receipts/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/clerical/receipts', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/clerical/receipts/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/clerical/receipts/${id}/duplicate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/clerical/receipts/${id}.pdf`, {
          method: 'GET',
          headers: {
            ...JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
            'Content-Type': 'application/octet-stream',
          },
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)

        fetch(`${baseURL}/api/v1/clerical/receipts.csv?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    remindReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/clerical/receipts/${id}/reminder`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidReceipt(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/clerical/receipts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivities(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/receipts/${params.id}/activity`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
