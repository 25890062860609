export default {
  Dashboard: 'Dashboard',
  'Knowledge Base': 'Knowledge Base',
  'Products & Services': 'Products & Services',
  'Payment Methods': 'Payment Methods',
  'Chart of Accounts': 'Chart of Accounts',
  Categories: 'Categories',
  Customers: 'Customers',
  Billing: 'Billing',
  Receipts: 'Receipts',
  Invoices: 'Invoices',
  Quotations: 'Quotations',
  Expenses: 'Expenses',
  Bills: 'Bills',
  Vendors: 'Vendors',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',

  // Table
  Datetime: 'Datetime',
  Description: 'Description',
  Status: 'Status',
  'Insurance Policy No.': 'Insurance Policy No.',
  Branch: 'Branch',
  Issuer: 'Issuer',
  'Car Registration No.': 'Car Registration No.',
  'Customer Name': 'Customer Name',
  'Company Name': 'Company Name',
  'Surplus Hibah': 'Surplus Hibah',
  NCD: 'NCD',
  'Sum Insured': 'Sum Insured',
  'Insurance Premium': 'Insurance Premium',
  'After Discount': 'After Discount',
  'Net Payment': 'Net Payment',
  'Road Tax': 'Road Tax',
  'Additional Fees': 'Additional Fees',
  Total: 'Total',
  Party: 'Party',
  'Payment Method': 'Payment Method',
  'Insurance Product': 'Insurance Product',
  Insurance: 'Insurance',
  'Bluecard Finance': 'Bluecard Finance',
  Period: 'Period',
  Profit: 'Profit',
  'General Remarks': 'General Remarks',
  Actions: 'Actions',

  // Invoices
  'Create Invoice': 'Create Invoice',
  'Search Invoice': 'Search Invoice',
  '#Invoice No.': '#Invoice No.',
  'Confirm Invoice': 'Confirm Invoice',
  Outstanding: 'Outstanding',
  Paid: 'Paid',
  'This invoice has been voided.': 'This invoice has been voided.',

  // Receipts
  'Create Receipt': 'Create Receipt',
  'Search Receipt': 'Search Receipt',
  '#Receipt No.': '#Receipt No.',
  'Confirm Receipt': 'Confirm Receipt',
  'This receipt has been voided.': 'This receipt has been voided.',

  // Quotations
  'Create Quotation': 'Create Quotation',
  'Search Quotation': 'Search Quotation',
  '#Quotation No.': '#Quotation No.',
  'Confirm Quotation': 'Confirm Quotation',
  'This quotation has been voided.': 'This quotation has been voided.',

  // Misc
  'Select Status': 'Select Status',
  'Select Branch': 'Select Branch',
  'Set Dates': 'Set Dates',
  'Select Product Types': 'Select Product Types',
  'Select Accounts': 'Select Accounts',
  'Select View': 'Select View',
  'Select Grouping': 'Select Grouping',
  'Select Insurances': 'Select Insurances',
  Rows: 'Rows',
  'Print Report': 'Print Report',
  Print: 'Print',
  'Print full report': 'Print full report',
  'Print runner report': 'Print runner report',
  'Summary for': 'Summary for',
  'Total collection by cash': 'Total collection by cash',
  'Total collection by bank': 'Total collection by bank',
  'Total collection by cheque': 'Total collection by cheque',
  'Total overall collection': 'Total overall collection',
  'View Log': 'View Log',
  Logout: 'Logout',
  Today: 'Today',
  'Last 7 days': 'Last 7 days',
  'Last 1 year': 'Last 1 year',
  'Profit Breakdown': 'Profit Breakdown',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Branches: 'Branches',
  Accounts: 'Accounts',
  Principles: 'Principles',
  'Total Customer Payment': 'Total Customer Payment',
  'Total Net Payment': 'Total Net Payment',
  'Total Profit': 'Total Profit',
  Settings: 'Settings',
  Account: 'Account',
  Nickname: 'Nickname',
  Name: 'Name',
  'E-mail': 'E-mail',
  'Enter New Password': 'Enter New Password',
  'Only change your password when necessary': 'Only change your password when necessary',
  Save: 'Save',
  'Please ensure that all fields are filled correctly as this record will not be editable after confirming.': 'Please ensure that all fields are filled correctly as this record will not be editable after confirming.',
  'How to use the search bar': 'How to use the search bar',
  'You can search using': 'You can search using',
  'How to use Autofill': 'How to use Autofill',
  'Press the autofill button and wait for the form to be filled automatically!': 'Press the autofill button and wait for the form to be filled automatically!',

  // Roles
  Clerk: 'Clerk',
  Auditor: 'Auditor',
  Admin: 'Admin',
}
