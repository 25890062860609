import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/sales_items', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/sales_items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/clerical/sales_items', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/clerical/sales_items/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/clerical/sales_items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/sales_categories', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/sales_categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/clerical/sales_categories', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/clerical/sales_categories/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/clerical/sales_categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/clerical/customers', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/customers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/clerical/customers', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/clerical/customers/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/clerical/customers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerStatement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/clerical/customers/${params.id}/statement`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadStatement(ctx, params) {
      return new Promise((resolve, reject) => {
        fetch(`${baseURL}/api/v1/clerical/customers/${params.id}/statement.pdf?start_date=${params.start_date}&end_date=${params.end_date}&status=${params.status}`, {
          method: 'GET',
          headers: {
            ...JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
            'Content-Type': 'application/octet-stream',
          },
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    remindStatement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/clerical/customers/${params.id}/reminder`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
