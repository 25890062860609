import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'
import { DirectUpload } from '@rails/activestorage'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBills(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/expenses/expense_bills', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/expense_bills/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/expenses/expense_bills', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/expenses/expense_bills/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateBill(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/expenses/expense_bills/${id}/duplicate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidBill(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/expenses/expense_bills/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)
        urlParams.append('status', params.status)

        fetch(`${baseURL}/api/v1/expenses/expense_bills.csv?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
    directUploadReceipt(ctx, file) {
      return new Promise((resolve, reject) => {
        const url = `${baseURL}/direct_uploads`
        const upload = new DirectUpload(file, url, {
          directUploadWillCreateBlobWithXHR: xhr => {
            const authHeaders = JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders')))
            if (authHeaders) {
              xhr.setRequestHeader('access-token', authHeaders.accessToken)
              xhr.setRequestHeader('token-type', authHeaders.tokenType)
              xhr.setRequestHeader('client', authHeaders.client)
              xhr.setRequestHeader('expiry', authHeaders.expiry)
              xhr.setRequestHeader('uid', authHeaders.uid)
            }
          },
        })

        upload.create((error, blob) => {
          if (error) reject(error)
          else resolve(blob)
        })
      })
    },
    fetchPayments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/expense_bills/${params.id}/payments`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/expense_payments/${params.id}`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createPayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/expenses/expense_payments', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePayment(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/expenses/expense_payments/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicatePayment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/expenses/expense_payments/${id}/duplicate`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidPayment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/expenses/expense_payments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivities(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/expense_bills/${params.id}/activity`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
