import { axios } from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/expenses/purchases_items', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/purchases_items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/expenses/purchases_items', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/expenses/purchases_items/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/expenses/purchases_items/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/expenses/purchases_categories', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/purchases_categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/expenses/purchases_categories', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/expenses/purchases_categories/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/expenses/purchases_categories/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVendors(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/expenses/vendors', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVendor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/expenses/vendors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createVendor(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/v1/expenses/vendors', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVendor(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/v1/expenses/vendors/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVendor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/expenses/vendors/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
